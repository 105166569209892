<template>
    <main>
        <HeaderTab :title="$t('horse.merge_horses')" />
        <div class="container-fluid">
            <div v-if="!horse_to_merge">
                <LoadingSpinner class="col-12" />
            </div>
            <div class="box mt-5" v-if="horse_to_merge">
                <p>{{ $t('global.merge_message') }}</p>
                <form @submit.prevent="validForm">
                    <div class="form-group">
                        <label>{{ $t('action.fusionner') }} {{ horse_to_merge.horse_nom }} {{ $t('global.in') }}:</label>
                        <e-select
                            v-model="horse"
                            id="horse_id"
                            track-by="horse_nom"
                            label="horse_nom"
                            :placeholder="$t('global.selectionner_cheval')"
                            :selectedLabel="$t('select.selectedLabel')"
                            :options="horses"
                            :searchable="true"
                            :allow-empty="false"
                            :loading="isLoadingHorses"
                            :show-labels="false"
                        >
                            <template slot="first" slot-scope="{ option }">{{ option.label }}</template>
                            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                        </e-select>
                    </div>

                    <ErrorAlert v-if="message_erreur_code !== ''" :messageI18n="message_erreur_code" />

                    <div class="text-center mt-5">
                        <button type="submit" v-on:click="checkForm" class="btn btn-primary rounded-pill">
                            <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
                            {{ $t('action.fusionner') }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <ModalConfirm
            ref="confirm_merge"
            id="confirm_merge"
            :icon="['far', 'save']"
            :icon_accept="['fal', 'level-down']"
            :text_title="$t('action.fusionner')"
            :text_question="$t('modal.merge_horse.message')"
            :text_button_ok="$t('modal.general.ok')"
            :text_button_accept="$t('modal.general.ok')"
            :text_button_cancel="$t('modal.general.annuler')"
            :callback_validation="confirmMergeHorses"
            :callback_cancel="cancelMergeHorses"
            :processing="processing"
        />
    </main>
</template>

<script type="text/javascript">
    import Config from '@/mixins/Config';
    import Horse from '@/mixins/Horse';
    import Tools from '@/mixins/Tools';

    export default {
        name: "MergeHorse",
        mixins: [Config, Horse, Tools],
        data () {
            return {
				horses: [],
				horse_to_merge: null,
                horse: null,
                required_values: ['horse'],
				message_erreur_code: '',
				isLoadingHorses: true,
                processing: false
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.horses = []
                this.horse_to_merge = await this.getHorseById(this.$route.params.horse_id)
                if(this.horse_to_merge.horse_sexe == 'F') {
                    this.horses = await this.getHorseFemelleAndIndefini()
                }
                else if(this.horse_to_merge.horse_sexe == 'M' || this.horse_to_merge.horse_sexe == 'H') {
                    this.horses = await this.getHorseMaleAndIndefini()
                }
                else {
                    this.horses = await this.getHorses()
                }

                this.horses.splice(this.horses.indexOf(this.horses.find(horse => horse.horse_nom == this.horse_to_merge.horse_nom)), 1)
				this.isLoadingHorses = false
            },

            async validForm() {
                this.$refs.confirm_merge.openModal()
            },
            checkForm() {
                let el = ''
                let error = false

                for(let i=0; i<this.required_values.length; i++) {
                    el = this.required_values[i]

                    if(this[el] === null || this[el] === undefined) {
                        error = true
                    }
                }

                if(error) {
                    this.message_erreur_code = "formulaire.erreur_champs_non_remplis"
					return false
                }

				return true
            },
            async confirmMergeHorses() {
                if(this.checkForm()) {
                    this.processing = true

                    if(this.horse_to_merge.horse_sire && !this.horse.horse_sire) {
                        let temp = this.horse_to_merge
                        this.horse_to_merge = this.horse
                        this.horse = temp
                    }

                    await this.mergeHorses(this.horse_to_merge.horse_id, this.horse.horse_id)
                    .then(async () => {
                        await this.$sync.force(true)
                        this.processing = false
                        this.cancelMergeHorses()
                        this.$router.push({ name: "horseFiche", params: {horse_id: parseInt(this.horse.horse_id)} })
                    })
				}
            },
            cancelMergeHorses() {
                this.$refs.confirm_merge.closeModal()
            }
        },
        components: {
            HeaderTab: () => import('@/components/HeaderTab'),
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
            LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            ModalConfirm: () => import('GroomyRoot/components/Modals/ModalConfirm')
        }
    };
</script>
